<template>
  <b-card no-body>
    <div class="m-2 ml-auto">
      <b-button
        id="invite-employee"
        variant="primary"
        @click="toggleDepartmentSidebar()"
      >
        Add Department
      </b-button>
    </div>

    <b-table
      responsive
      primary-key="id"
      show-empty
      empty-text="No matching records found"
      :items="departmentList"
      :fields="tableColumns"
      :busy="isTableLoading"
    >
      <template #table-busy>
        <div class="text-center text-primary m-2">
          <b-spinner class="align-middle" />
          <strong> Loading...</strong>
        </div>
      </template>
      <template #cell(three_dot)="data">
        <b-dropdown
          variant="link"
          toggle-class="p-0"
          class="action-menu"
          no-caret
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>
          <b-dropdown-item @click="toggleDepartmentSidebar(data.item)">
            <feather-icon icon="Edit2Icon" />
            <span
              class="align-middle ml-50"
            >
              Edit
            </span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
      <template #cell(name)="data">
        {{ data.item.name ? `${data.item.name}` : 'N/A' }}
      </template>
    </b-table>
    <TablePagination
      :offset="meta.offset"
      :total="meta.total"
      :limit="meta.limit"
      :list-data="departmentList"
      @paginate="paginate"
    />
    <DepartmentForm
      :department-info="departmentInfo"
      @getDepartmentList="getDepartmentList"
    />
  </b-card>
</template>

<script>
import api from '@/libs/api'
import helper from '@/mixins/helper'
import TablePagination from '@/components/common/TablePagination.vue'
import DepartmentForm from './DeparmentForm.vue'

export default {
  name: 'DepartMents',
  components: {
    TablePagination,
    DepartmentForm,
  },
  mixins: [helper],
  data() {
    return {
      isTableLoading: false,
      departmentList: [],
      meta: {
        offset: 0,
        limit: 10,
        total: 0,
      },
      tableColumns: [
        { key: 'three_dot', label: '' },
        { key: 'name', label: 'Department Name' },
        {
          key: 'no_of_employees', label: 'Number of employees', thClass: 'md',
        },
        {
          key: 'travelling', label: 'Travelling', thClass: 'md',
        },
        {
          key: 'upcoming_travel', label: 'Upcoming Travel',
        },
        {
          key: 'travelled', label: 'Travelled', thClass: 'md',
        },
      ],
      departmentInfo: {},
    }
  },
  mounted() {
    this.getDepartmentList()
  },
  methods: {
    getDepartmentList() {
      this.isTableLoading = true
      api.getData(`b2e_dashboard/department/?offset=${this.meta.offset}`, true).then(response => {
        if (response.data.status) {
          this.departmentList = response.data.result.results
          this.meta = response.data.result.meta
        } else {
          this.notificationMessage('danger', 'XIcon', 'Error', response.data.error.message)
        }
      }).catch().finally(() => {
        this.isTableLoading = false
      })
    },
    paginate(offset) {
      this.meta.offset = offset
      this.getDepartmentList()
    },
    toggleDepartmentSidebar(data) {
      if (data) {
        this.departmentInfo = data
      } else {
        this.departmentInfo = {}
      }
      this.$root.$emit('bv::toggle::collapse', 'add-department-sidebar')
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
