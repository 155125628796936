<template>
  <b-sidebar
    id="add-department-sidebar"
    ref="sidebar"
    class="department-sidebar"
    width="50%"
    title="Add Department"
    bg-variant="white"
    right
    shadow
    backdrop
    no-header
    @hidden="resetData()"
    @shown="getDepartmentData()"
  >
    <template #default="{ hide }">
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5
          class="mb-0"
        >
          {{ isObjectEmpty(departmentInfo) ? 'Edit Department': 'Add Department' }}
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <b-card
        no-body
        class="container my-1"
      >
        <validation-observer ref="department-form">
          <b-form ref="user-invite-form">
            <b-row>
              <b-col md="12">
                <validation-provider
                  #default="{ errors }"
                  name="name"
                  rules="required"
                >
                  <b-form-group
                    id="name"
                    label="Department name"
                    label-for="name"
                  >
                    <b-input-group>
                      <b-form-input
                        id="name"
                        v-model="departmentDetail.name"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="12">
                <b-form-group
                  id="description"
                  label="Description"
                  label-for="description"
                >
                  <b-input-group>
                    <b-form-textarea
                      id="textarea"
                      v-model="departmentDetail.description"
                      rows="3"
                      max-rows="6"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
        <div class="d-flex justify-content-start">
          <b-button
            variant="primary"
            class="mr-2"
            :disabled="isBtnLoading"
            @click="validateForm()"
          >
            <b-spinner
              v-if="isBtnLoading"
              small
            />
            Done
          </b-button>
          <b-button
            variant="outline-primary"
            @click="hide"
          >
            Close
          </b-button>
        </div>
      </b-card>
    </template>
  </b-sidebar>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import helper from '@/mixins/helper'
import api from '@/libs/api'

export default {
  name: 'DepartmentForm',
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [helper],
  props: {
    departmentInfo: {
      type: Object,
    },
  },
  data() {
    return {
      required,
      isBtnLoading: false,
      departmentDetail: {},
    }
  },
  methods: {
    createOrUpdateDepartment() {
      this.isBtnLoading = true
      let url = 'b2e_dashboard/department/'
      let apiResponse
      const departmentExists = this.departmentDetail.id

      if (departmentExists) {
        url = `b2e_dashboard/department/${this.departmentInfo.id}/`
        apiResponse = api.updateData(url, true, this.departmentDetail)
      } else {
        apiResponse = api.postData(url, true, this.departmentDetail)
      }
      this.callDepartmentApi(apiResponse, departmentExists)
    },
    getDepartmentData() {
      this.departmentDetail = JSON.parse(JSON.stringify(this.departmentInfo))
    },
    validateForm() {
      this.$refs['department-form'].validate().then(success => {
        if (success) {
          this.createOrUpdateDepartment()
        } else {
          this.notificationMessage('warning', 'XIcon', 'Error', 'Please fill up all required fields')
        }
      })
    },
    callDepartmentApi(apiResponse, isExists) {
      apiResponse.then(response => {
        if (response.data.status) {
          this.notificationMessage('success', 'CheckIcon', 'Success', `department ${isExists ? 'updated' : 'created'} successfully`)
          this.fetchDepartmentList()
        } else {
          this.notificationMessage('danger', 'Xicon', 'Error', JSON.stringify(response.data.error.message))
        }
      }).catch(() => {
        this.notificationMessage('danger', 'XIcon', 'Error', 'Sorry, something went wrong!')
      }).finally(() => {
        this.isBtnLoading = false
      })
    },
    resetData() {
      this.departmentDetail = {
        name: null,
        description: null,
      }
    },
    fetchDepartmentList() {
      this.$emit('getDepartmentList')
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
